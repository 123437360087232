<template>
    <div id="addContractOrder">
        <div class="headBox">
            <el-button
                type="primary"
                icon="el-icon-caret-left"
                plain
                size="mini"
                @click="$router.push({ name: 'contractOrder' })"
                >返回</el-button
            >
            <div class="title">{{ dialogTitle }}</div>
        </div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px" class="form">
            <el-card class="box-card" shadow="hover">
                <div slot="header" class="clearfix">
                    <div class="header_tit">客户信息</div>
                    <i class="el-icon-paperclip"></i>
                </div>
                <el-form-item label="客户姓名" prop="customerName">
                    <el-input
                        v-model="form.customerName"
                        size="mini"
                        style="width: 320px"
                        @blur="jianyan('customerName')"
                        placeholder="输入客户姓名"
                    ></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.iscustomerName"></i>
                    <i
                        class="el-icon-error status"
                        style="color: #f56c6c"
                        v-else-if="rules.iscustomerName == false"
                    ></i>
                </el-form-item>
                <el-form-item label="身份证号码" prop="idCard">
                    <el-input v-model="form.idCard" size="mini" @blur="jianyan('idCard')" placeholder="输入身份证号码"></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.isidCard"></i>
                    <i class="el-icon-error status" style="color: #f56c6c" v-else-if="rules.isidCard == false"></i>
                </el-form-item>
                <el-form-item label="证件照" class="zjz">
                    <el-upload
                        class="avatar-uploader"
                        action="null"
                        :show-file-list="false"
                        :on-success="fronthandleAvatarSuccess"
                        :before-upload="frontbeforeAvatarUpload"
                    >
                        <img v-if="frontImageUrl" :src="frontImageUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <el-upload
                        class="avatar-uploader"
                        action="null"
                        :show-file-list="false"
                        :on-success="backhandleAvatarSuccess"
                        :before-upload="backbeforeAvatarUpload"
                    >
                        <img v-if="backImageUrl" :src="backImageUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div class="el-upload__tip" slot="tip">只能上传图片，且不超过5MB</div>
                    </el-upload>
                </el-form-item>
            </el-card>
            <el-card class="box-card" shadow="hover">
                <div slot="header" class="clearfix">
                    <div class="header_tit">签约信息</div>
                    <i class="el-icon-paperclip"></i>
                </div>
                <el-form-item label="签约银行" prop="signingBank">
                    <el-input v-model="form.signingBank" size="mini" @blur="jianyan('signingBank')" placeholder="输入签约银行"></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.issigningBank"></i>
                    <i class="el-icon-error status" style="color: #f56c6c" v-else-if="rules.issigningBank == false"></i>
                </el-form-item>
                <el-form-item label="银行卡号" prop="bankCard">
                    <el-input v-model="form.bankCard" size="mini" @blur="jianyan('bankCard')" placeholder="输入银行卡号"></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.isbankCard"></i>
                    <i class="el-icon-error status" style="color: #f56c6c" v-else-if="rules.isbankCard == false"></i>
                </el-form-item>
                <el-form-item label="银行预留手机号" prop="phoneNumber">
                    <el-input v-model="form.phoneNumber" size="mini" @blur="jianyan('phoneNumber')" placeholder="输入银行预留手机号"></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.isphoneNumber"></i>
                    <i class="el-icon-error status" style="color: #f56c6c" v-else-if="rules.isphoneNumber == false"></i>
                </el-form-item>
                <el-form-item label="签约金额" prop="signingAmount">
                    <el-input
                        v-model="form.signingAmount"
                        size="mini"
                        @input="handleInput"
                        @blur="jianyan('signingAmount')"
                        placeholder="输入签约金额"
                    ></el-input>
                    元
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.issigningAmount"></i>
                    <i
                        class="el-icon-error status"
                        style="color: #f56c6c"
                        v-else-if="rules.issigningAmount == false"
                    ></i>
                </el-form-item>
                <el-form-item label="支付平台" prop="signingAmount">
                    <el-select v-model="form.paymentPlatformType" clearable placeholder="选择支付平台" size="mini" style="width: 320px;">
                        <el-option
                            v-for="item in platformOptions"
                            :key="item.paymentPlatformType"
                            :label="item.paymentPlatformTypeDesc"
                            :value="item.paymentPlatformType"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="是否分期">
                    <el-switch v-model="form.isStage" active-color="#13ce66" inactive-color="#ccc"></el-switch>
                </el-form-item>
                <el-form-item label="分期数" v-if="form.isStage" prop="stageNumber">
                    <el-input v-model="form.stageNumber" size="mini" @blur="jianyan('stageNumber')" placeholder="输入分期数"></el-input>
                    <i class="el-icon-success status" style="color: #67c23a" v-if="rules.isstageNumber"></i>
                    <i class="el-icon-error status" style="color: #f56c6c" v-else-if="rules.isstageNumber == false"></i>
                </el-form-item>
            </el-card>
        </el-form>
        <span class="footer">
            <el-button type="primary" plain @click="add('form')" :loading="saveLoading">保存</el-button>
        </span>
    </div>
</template>
<script>
import contractOrderApi from "../../api/contractOrderApi";
import axios from "axios";
export default {
    name: "addContractOrder",
    components: {},
    data() {
        return {
            form: {},
            rules: {
                customerName: [{ required: true, message: "未输入客户名称", trigger: "blur" }],
                iscustomerName: null,
                phoneNumber: [
                    { required: true, message: "未输入银行预留手机号码", trigger: "blur" },
                    { validator: this.validateMobile, trigger: "blur" }
                ],
                isphoneNumber: null,

                idCard: [
                    { required: true, message: "未输入身份证号码", trigger: "blur" },
                    { validator: this.validateIdNumber, trigger: "blur" }
                ],
                isidCard: null,
                signingBank: [{ required: true, message: "未输入签约银行", trigger: "blur" }],
                issigningBank: null,
                bankCard: [
                    { required: true, message: "未输入银行卡号", trigger: "blur" },
                    { validator: this.validatebankCard, trigger: "blur" }
                ],
                isbankCard: null,
                signingAmount: [{ required: true, message: "未输入签约金额", trigger: "blur" }],
                issigningAmount: null,
                stageNumber: [
                    { required: true, message: "未输入分期数", trigger: "blur" },
                    { validator: this.validatestageNumber, trigger: "blur" }
                ],
                isstageNumber: null
            },
            backImageUrl: "",
            frontImageUrl: "",
            saveLoading: false,
            dialogTitle: "创建签约订单",
            editId: null,

            platformOptions:[]
        };
    },
    mounted() {
        if (this.$route.query.id) {
            this.editId = this.$route.query.id;
            this.dialogTitle = "编辑签约订单";
                contractOrderApi
                    .orderList({
                        customerName: this.customerName || null,
                        id: this.$route.query.id || null,
                        signingStatus: this.signingStatus || null,
                        signingType: this.signingType || null
                    })
                    .then(res => {
                        if (res.success) {
                            var obj={
                                id:res.data.records[0].id,
                                customerName:res.data.records[0].customerName,
                                idCard:res.data.records[0].idCard,
                                signingBank:res.data.records[0].signingBank,
                                bankCard:res.data.records[0].bankCard,
                                phoneNumber:res.data.records[0].phoneNumber,
                                signingAmount:res.data.records[0].signingAmount,
                                paymentPlatformType:res.data.records[0].paymentPlatformType,
                                isStage:res.data.records[0].isStage,
                                stageNumber:res.data.records[0].stageNumber,
                            }
                            if (!res.data.records[0].stageNumber) {
                                this.$delete(obj,'stageNumber')
                            }
                           
                            this.form = obj;
                            

                            this.frontImageUrl =
                                "https://safepayment.com.cn:18081/api/attachment" + res.data.records[0].idCardFrontImageSrc+'?token='+this.$utils.storage.get('X-Access-Token');
                            this.backImageUrl =
                                "https://safepayment.com.cn:18081/api/attachment" + res.data.records[0].idCardBackImageSrc+'?token='+this.$utils.storage.get('X-Access-Token');
                        }
                    });
          
        } else {
            this.dialogTitle = "创建签约订单";
        }

        this.getPlatformList()
    },
    methods: {
        add(formName) {
        
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.saveLoading = true;
                    this.form.settlementType = "T1";
                    this.form.signingType = this.form.isStage ? true : false;
                    let formData = new FormData();
                    for (let key in this.form) {
                        formData.append(key, this.form[key]);
                    }
                    if (this.dialogTitle == "创建签约订单") {
                        contractOrderApi
                            .addOrder(formData)
                            .then(res => {
                                if (res.success) {
                                    this.$message({
                                        type: "success",
                                        message: "创建成功"
                                    });
                                    this.$router.push({ name: "contractOrder" });
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: "创建失败"
                                    });
                                }
                                this.saveLoading = false;
                            })
                            .catch(error => {
                                this.$message({
                                    type: "warning",
                                    message: "只能上传图片，且不超过5MB"
                                });
                            });
                    } else {
                        this.form.id = this.$route.query.id;
                        let formData = new FormData();
                        for (let key in this.form) {
                            formData.append(key, this.form[key]);
                        }

                      
                        contractOrderApi
                            .updateOrder(formData)
                            .then(res => {
                                if (res.success) {
                                    this.$message({
                                        type: "success",
                                        message: "修改成功"
                                    });
                                    this.$router.push({ name: "contractOrder" });
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: "修改失败"
                                    });
                                }
                            })
                            .catch(error => {
                                this.$message({
                                    type: "warning",
                                    message: "只能上传图片，且不超过5MB"
                                });
                            });
                    }
                } else {
                    return false;
                }
            });
        },

        getPlatformList(){
            contractOrderApi.payPlatformList({
                tenantId:this.$utils.storage.get("shInfo").id
            }).then(res => {
                if (res.success) {
                    this.platformOptions = res.data;
                    console.log(this.platformOptions);
                    
                }
            });
        },

        validateMobile(rule, value, callback) {
            const reg = /^1[3456789]\d{9}$/;
            if (value === "") {
                callback(new Error("未输入银行预留手机号码"));
            } else if (!reg.test(value)) {
                callback(new Error("未输入正确的手机号码"));
            } else {
                callback();
            }
        },
        validateIdNumber(rule, value, callback) {
            if (!value) {
                callback(new Error("未输入身份证号"));
            } else {
                const reg = /^\w{18}$/;
                if (!reg.test(value)) {
                    callback(new Error("未输入正确的身份证号码"));
                } else {
                    callback();
                }
            }
        },
        validatebankCard(rule, value, callback) {
            if (!value) {
                callback(new Error("未输入银行卡号"));
            } else {
                const reg = /^\d{16,19}$/;
                if (!reg.test(value)) {
                    callback(new Error("未输入正确的银行卡号"));
                } else {
                    callback();
                }
            }
        },
        validatestageNumber(rule, value, callback) {
            if (value === "") {
                callback(new Error("未输入分期数"));
            } else {
                const reg = /^[0-9]*$/;
                if (!reg.test(value)) {
                    callback(new Error("未输入整数"));
                } else {
                    callback();
                }
            }
        },
        fronthandleAvatarSuccess(res, file) {
            this.frontImageUrl = URL.createObjectURL(file.raw);
        },
        backhandleAvatarSuccess(res, file) {
            this.backImageUrl = URL.createObjectURL(file.raw);
        },
        backbeforeAvatarUpload(file) {
            this.form.idCardBackImage = file;
            console.log(this.form.idCardBackImage);

            this.backImageUrl = URL.createObjectURL(file);
            if (this.form.idCardBackImageSrc != "") {
                this.$delete(this.form, "idCardBackImageSrc");
            }

            return false;
        },
        frontbeforeAvatarUpload(file) {
            this.form.idCardFrontImage = file;
            this.frontImageUrl = URL.createObjectURL(file);
            if (this.form.idCardFrontImageSrc != "") {
                this.$delete(this.form, "idCardFrontImageSrc");
            }
            return false;
        },
        handleInput(value) {
            const cleanValue = value.replace(/[^0-9.]/g, "");
            const validValue = cleanValue.split(".").reduce((acc, part, index, array) => {
                if (index === 0) {
                    return acc + part;
                } else if (index === 1 && array[0] !== "") {
                    return acc + "." + part;
                }
                return acc;
            }, "");
            this.form.signingAmount = validValue;
        },

        jianyan(prop) {
            this.$refs.form.validateField(prop, errorMessage => {
                if (!errorMessage) {
                    this.rules["is" + prop] = true;
                } else {
                    this.rules["is" + prop] = false;
                }
            });
        }
    }
};
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
.avatar {
    width: 120px;
    height: 120px;
    display: block;
}
</style>
<style lang="scss" scoped>
#addContractOrder {
    background: #ffffff;
    padding: 20px 20px 20px 17%;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto;
        padding-right: 100px;
    }
    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        width: 80%;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
        }
    }
    .header_tit {
        font-weight: 700;
        font-size: 16px;
        padding-left: 10px;
    }
    .infoBox {
        .infoItem {
            border-bottom: 1px solid #eee;
            padding: 0 10px 4px 10px;
            margin: 0 10px 20px;
            display: flex;
            justify-content: space-between;
            &:last-child {
                border: 0;
            }
            .tit {
                font-weight: 700;
            }
            .zjz {
                margin-left: 50px;
                margin-top: 5px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                .img {
                    width: 120px;
                    height: 120px;
                    border: 1px dashed #d9d9d9;
                    margin-top: 15px;
                    &:first-child {
                        margin-bottom: 15px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .zjzInfo {
            display: block;
            margin: 0 10px 15px;
        }
    }

    .form {
        /deep/ .el-input {
            width: 320px;
        }
        .el-form-item {
            padding: 0 20px 0 30px;
            margin-bottom: 15px;
            .status {
                margin: 5px 0 0 10px;
            }
        }
        .el-upload__tip {
            color: #e6a23c;
        }
    }
    .box-card {
        margin-bottom: 30px;
        width: 80%;
        /deep/.el-card__header {
            background: #f4f4f5;
        }
        .clearfix {
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        width: 80%;
    }
}
@media (max-width: 600px) {
    #addContractOrder {
        padding: 16px;
        .title {
            padding-right: 0;
        }
        .box-card {
            width: 100%;
        }
        .headBox {
            width: 100%;
        }
        .form {
            /deep/ .el-input {
                width: 100%;
            }
            .el-form-item {
                padding: 0;
            }
            /deep/ .el-form-item__label {
                width: 92px !important;
            }
            /deep/ .el-form-item__content {
                margin-left: 0 !important;
                display: flex;
                flex-wrap: wrap;
            }
        }
        .footer {
            width: 100%;
        }
    }
}
</style>

